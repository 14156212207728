/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "code",
    label: "字典名称",
    type: "select-data-search",
    iw: 160,
    props: {
      placeholder: "请输入字典名称"
    },
    options: [],
    refreshList: null
  },
  {
    field: "code",
    label: "字典编码",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入编码"
    }
  }
];
