<template>
  <div class="data-table-box mb-54">
    <div class="title">
      <p class="fw-600 fz-16">{{ title }}</p>

      <slot v-if="title && title.split('/').length < 3" name="addButton"></slot>
    </div>
    <!-- 列表 -->
    <vxe-table :data="list" width="100%">
      <vxe-table-column type="seq" width="84" title="主键" fixed="left" />
      <vxe-table-column field="name" title="字典名称" min-width="128">
        <template v-slot="{ row }">
          <span
            v-if="!row.all_path"
            class="clickable-name"
            @click="handleGetDetailInfo(row.code)"
          >
            {{ row.name }}
          </span>
          <span v-else>{{ row.name }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="code" title="字典编码" min-width="151">
      </vxe-table-column>
      <vxe-table-column field="value" title="值" min-width="297" />
      <vxe-table-column field="sort" title="排列号" min-width="113" />
      <vxe-table-column title="操作" width="130" fixed="right">
        <template v-slot="{ row }">
          <a-button type="link" @click="handleEdit(row)">
            修改
          </a-button>
          <a-button
            type="link"
            @click="handleDelete(row)"
            style="padding-left:0;"
          >
            删除
          </a-button>
        </template>
      </vxe-table-column>

      <!-- 空数据 -->
      <template #empty>
        <slot name="empty">
          没有更多数据了！
        </slot>
      </template>
    </vxe-table>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleOk="handleOk"
      @handleCancel="deleteModal = false"
    >
      <template #content>
        <p class="text-center">确定要删除此条字典？</p>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { message } from "ant-design-vue";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useDictDeleteApi } from "@/apis/system";

export default {
  name: "InternetWarning",
  components: { xModal },
  props: {
    height: { type: String, default: "" },
    title: { type: String, default: "" },
    list: { type: Array, default: () => [] }
  },
  setup(_, ctx) {
    const dictDeleteApi = useDictDeleteApi();
    const visible = ref(false);
    const deleteModal = ref(false);

    // 字典id
    const info = ref({});

    // 修改
    const handleEdit = row => {
      console.log(row);
      ctx.emit("editInfo", row);
    };

    // 删除
    const handleDelete = row => {
      info.value = row;
      deleteModal.value = true;
    };

    // 确认删除
    const handleOk = async () => {
      const [, res] = await $await(dictDeleteApi({ id: info.value.id }));
      if (res) {
        deleteModal.value = false;
        let code;
        if (info.value.all_path) {
          // 如果有all_path, 说明修改的是当前选中字典
          code = info.value.code;
        } else {
          // 如果没有all_path,说明修改的是当前选中的下级字典
          code = info.value.p_code;
        }
        ctx.emit("getData", code);
        ctx.emit("getTreeData");
        message.success("删除成功！");
      }
    };

    // 查看详情
    const handleGetDetailInfo = code => {
      ctx.emit("getData", code);
    };

    return {
      visible,
      handleOk,
      handleEdit,
      handleDelete,
      deleteModal,
      handleGetDetailInfo
    };
  }
};
</script>

<style lang="less" scoped>
.data-table-box {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #363636;
    margin-bottom: 16px;
    .line {
      border-top: 1px solid #d4d4d4;
      flex: 1;
      margin: 0 8px;
    }
    .btn {
      cursor: pointer;
      color: #007aff;
    }
  }
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.clickable-name {
  color: #1890ff;
  cursor: pointer;
}
</style>
