<template>
  <div>
    <xModal
      :title="modalTitle[actionType]"
      :visible="visible"
      :w="494"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 字典分类 -->
          <FormItem label="字典分类" name="type" v-if="actionType === 1">
            <a-select
              v-model:value="formState.type"
              placeholder="请选择"
              style="width:320px;"
              @change="handleTypeChange"
            >
              <a-select-option value="WLHY">网络货运</a-select-option>
              <a-select-option value="TAX">税</a-select-option>
            </a-select>
          </FormItem>

          <!-- 上级字典 -->
          <FormItem label="上级字典" name="type" v-if="actionType === 2">
            <p class="input-grey">{{ dataInfo.name }}</p>
          </FormItem>

          <!-- 字典名称 -->
          <FormItem label="字典名称" name="name">
            <a-input
              v-model:value="formState.name"
              style="width:320px;"
              placeholder="请输入名称"
            />
          </FormItem>

          <!-- 字典编码-->
          <FormItem label="字典编码" name="code" v-if="actionType !== 3">
            <a-input
              v-model:value="formState.code"
              style="width:320px;"
              placeholder="请输入编码"
            />
          </FormItem>

          <!-- 值-->
          <FormItem label="值" name="value">
            <a-input
              v-model:value="formState.value"
              style="width:320px;"
              placeholder="请输入值"
            />
          </FormItem>

          <!-- 排列号-->
          <FormItem label="排列号" name="sort">
            <a-input
              v-model:value="formState.sort"
              style="width:320px;"
              placeholder="请输入排列号"
            />
          </FormItem>

          <!-- 备注 -->
          <FormItem label="备注">
            <a-textarea
              placeholder="请输入备注（非必填）"
              showCount
              :maxlength="50"
              :autoSize="{ minRows: 3, maxRows: 3 }"
              v-model:value="formState.description"
            />
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import { useStore } from "vuex";
import xModal from "@/components/xModal";
import { getInvoiceRules } from "./config";
import $await from "@/utils/await";
import { useDictAddApi, useDictUpdateApi } from "@/apis/system";
import { modalTitle } from "./Constants/index";

export default {
  props: {
    type: { type: String, default: "WLHY" },
    code: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    actionType: { type: Number, default: 0 },
    dataInfo: { type: Object, default: () => {} }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const store = useStore();
    const dictAddApi = useDictAddApi();
    const dictUpdateApi = useDictUpdateApi();

    const isLoading = ref(false);
    const formRef = ref();
    const dictionaries = store.getters.dictionaries;

    // 表单信息
    const formState = ref();

    if (props.actionType === 3) {
      formState.value = {
        type: props.type,
        name: props.dataInfo.name,
        value: props.dataInfo.value,
        sort: props.dataInfo.sort,
        description: props.dataInfo.description
      };
    } else {
      formState.value = {
        type: props.type,
        name: "",
        code: "",
        value: "",
        sort: "",
        description: ""
      };
    }

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      // 添加
      if (props.actionType === 1 || props.actionType === 2) {
        const data = {
          p_code: (props.actionType === 2 && props.dataInfo?.code) || 0,
          ...formState.value
        };
        formRef.value
          .validate()
          .then(async () => {
            isLoading.value = true;
            const [, res] = await $await(dictAddApi(data));
            if (res) {
              isLoading.value = false;
              message.success("字典新建完成！");
              handleCancel();
              if (props.actionType === 1) {
                // 更新树
                ctx.emit("getTreeData");
              } else {
                // 更新列表
                ctx.emit("getData", props.dataInfo?.code);
              }
            } else {
              isLoading.value = false;
            }
          })
          .catch(error => {
            console.log("error", error);
          });
        // 编辑
      } else {
        formRef.value.validate().then(async () => {
          const data = {
            ...formState.value,
            code: props.dataInfo?.code || 0,
            id: props.dataInfo?.id
          };
          const [, res] = await $await(dictUpdateApi(data));
          if (res) {
            isLoading.value = false;
            message.success("修改成功！");
            handleCancel();
            let code;
            if (props.dataInfo?.all_path) {
              // 如果有all_path, 说明修改的是当前选中字典
              code = props.dataInfo?.code;
            } else {
              // 如果没有all_path,说明修改的是当前选中的下级字典
              code = props.dataInfo?.p_code;
            }
            ctx.emit("getData", code);
          } else {
            isLoading.value = false;
          }
        });
      }
    };

    // 更换字典分类
    const handleTypeChange = val => {
      formState.value.type = val;
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    return {
      isLoading,
      formRef,
      formState,
      rules,
      dictionaries,
      handleCancel,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleSubmit,
      handleTypeChange,
      modalTitle
    };
  }
};
</script>

<style lang="less" scoped>
.input-grey {
  width: 320px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  line-height: 32px;
}
</style>
<style lang="less"></style>
