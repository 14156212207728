// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    type: [
      {
        required: true,
        trigger: "blur",
        message: "字典类型不能为空"
      }
    ],
    name: [
      {
        required: true,
        trigger: "blur",
        message: "字典名称不能为空"
      }
    ],
    code: [
      {
        required: true,
        trigger: "blur",
        message: "字典编码不能为空"
      }
    ],
    value: [
      {
        required: true,
        trigger: "blur",
        message: "字典值不能为空"
      }
    ],
    sort: [
      {
        type: "any",
        required: true,
        trigger: "blur",
        message: "字典排列号不能为空"
      }
    ]
  };

  return rules;
};
