<template>
  <div class="page-main-box pt-24 data-dictionaries">
    <!-- 搜索头 -->
    <search-header
      class="ml-16 mr-16"
      :schemaData="schema"
      @getDataList="handleFormSearch"
    />

    <div class="bdt">
      <div class="left bdr">
        <div class="mt-16 ml-24">
          <span class="text_note mr-8">字典分类</span>
          <a-select
            v-model:value="type"
            style="width: 140px"
            ref="select"
            @change="handleTypeChange"
          >
            <a-select-option value="WLHY">网络货运</a-select-option>
            <a-select-option value="TAX">税</a-select-option>
          </a-select>
        </div>

        <div class="bda tree-box mt-16 ml-24 pl-16">
          <Tree
            showLine
            :load-data="onLoadData"
            :tree-data="treeData"
            @select="handleTreeSelect"
          >
            <template #switcherIcon>
              <Icon name="iconGroup372" height="10" width="10"></Icon>
            </template>
            <template #Null>
              <Icon name="iconGroup291"></Icon>
            </template>
          </Tree>
        </div>
        <div class="mt-16 d-flex justify-center">
          <a-button
            class="fz-12 "
            type="primary"
            @click="
              editVisible = true;
              actionType = 1;
            "
            >新建一级字典</a-button
          >
        </div>
      </div>

      <div class="right">
        <DataTable
          :title="dataList[0]?.all_path"
          :list="dataList"
          @getData="handleTreeSelect"
          @getTreeData="getDataList"
          @editInfo="handleEditInfo"
        >
          <template #addButton>
            <a-button
              class="fz-12"
              style="border:1px solid #007aff; color:#007aff;"
              @click="
                actionType = 2;
                editVisible = true;
                dataInfo = dataList[0];
              "
            >
              新建下级字典
            </a-button>
          </template>
        </DataTable>
        <DataTable
          v-if="dataList[0]?.all_path.split('/').length < 3"
          @getData="handleTreeSelect"
          @getTreeData="getDataList"
          @editInfo="handleEditInfo"
          height="340px"
          :title="
            dataList.length
              ? dataList[0].all_path + '字典明细'
              : '' + '字典明细'
          "
          :list="dataChildrenList"
        >
          <template #empty>
            <span style="color: #c1c1c1;">
              <img
                src="@/assets/image/empty.png"
                style="height:85px; width:100px;"
              />
              <p class="fz-12 mt-8">暂无字典明细</p>
            </span>
          </template>
        </DataTable>
      </div>
    </div>

    <!-- 新增/编辑字典 -->
    <AddData
      v-if="editVisible"
      :visible="editVisible"
      :actionType="actionType"
      :dataInfo="dataInfo"
      :type="type"
      @getTreeData="getDataList"
      @getData="handleTreeSelect"
      @cancel="handleCloseEditModal"
    ></AddData>
  </div>
</template>

<script>
import { ref, toRaw, reactive } from "vue";
import { Tree } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import useSearchForm from "@/hooks/UseSearchForm";
import DataTable from "./components/DataTable/index";
import AddData from "./components/AddData/index";
import { useDictListApi, useDictQueryByCodeApi } from "@/apis/system";
import $await from "@/utils/await";
import useDebounce from "@/hooks/useDebounce";
import useSearch from "@/hooks/useSearch";

export default {
  name: "DataDictionaries",
  components: { Tree, DataTable, AddData },
  setup() {
    const dictListApi = useDictListApi();
    const dictQueryByCodeApi = useDictQueryByCodeApi();

    const editVisible = ref(false);
    // 1：新增一级字典 ，2：新增下级字典， 3：编辑字典
    const actionType = ref(0);

    // 字典名称搜索
    const handleSearch = useDebounce(async () => {
      const [, res] = await $await(dictListApi({}));
      useSearch(searchHeaderOptions, res, "code", false);
    }, 500);

    handleSearch();

    let enumeration = searchHeaderOptions.find(v => v.label === "字典名称");
    enumeration.props.onSearch = handleSearch;

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 9999,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          code: toRaw(filter.value).code || toRaw(filter.value).name
        };
        const [, res] = await $await(dictQueryByCodeApi(data));
        if (res.id) {
          dataList.value = [res];
          dataChildrenList.value = res.dict_info_responses;
        }

        return [res];
      }
    });

    // 字典类型
    const type = ref("TAX");
    // 字典信息
    const dataList = ref([]);
    // 当前字典信息
    const dataInfo = ref({});
    // 子字典列表
    const dataChildrenList = ref([]);
    // 已展开的字典
    const selectData = ref([]);

    // 更改字典分类
    const handleTypeChange = () => {
      getDataList();
    };

    const treeData = ref();

    // 获取左侧树列表
    const getDataList = async val => {
      console.log(val);
      const [, res] = await $await(
        dictListApi({ p_code: 0, type: type.value })
      );
      if (res) {
        treeData.value = res.map(item => {
          item.title = item.label;
          item.key = item.value;
          item.isLeaf = false;

          // 如果不是删除和新增操作并且有已展开的字典，直接替换
          selectData.value.map(item1 => {
            if (item.key === item1.key) {
              item = item1;
            }
          });
          return item;
        });
      }
    };
    getDataList();

    // 点击字典动态获取子字典
    const onLoadData = treeNode => {
      const data = treeNode.dataRef;
      // 记录以展开的字典,切换tab后直接展示
      selectData.value.push(data);
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        (async () => {
          const [, res] = await $await(
            dictListApi({ p_code: treeNode.value, type: type.value })
          );
          if (res) {
            treeNode.dataRef.children = res.map(item => {
              item.title = item.label;
              item.key = item.value;
              return item;
            });
            treeData.value = [...treeData.value];
            resolve();
          }
        })();
      });
    };

    // 选中字典
    const handleTreeSelect = async selectedKeys => {
      if (!selectedKeys.length) {
        return;
      }
      if (selectedKeys instanceof Array) {
        selectedKeys = selectedKeys[0];
      }
      const [, res] = await $await(dictQueryByCodeApi({ code: selectedKeys }));
      if (res.id) {
        dataList.value = [res];
        dataChildrenList.value = res.dict_info_responses;
      } else {
        dataList.value = [];
        dataChildrenList.value = [];
      }
    };

    // 关闭新增/编辑弹窗
    const handleCloseEditModal = () => {
      editVisible.value = false;
    };

    // 编辑
    const handleEditInfo = row => {
      actionType.value = 3;
      dataInfo.value = row;
      editVisible.value = true;
    };

    return {
      type,
      treeData,
      dataInfo,
      dataList,
      dataChildrenList,
      onLoadData,
      refresh,
      handleFormSearch,
      handleTypeChange,
      handleTreeSelect,
      schema: searchHeaderOptions,
      editVisible,
      actionType,
      handleCloseEditModal,
      getDataList,
      handleEditInfo
    };
  }
};
</script>

<style lang="less" scoped>
.left {
  height: calc(100% - 144px);
  position: fixed;
  width: 280px;

  .tree-box {
    width: 240px;
    border-radius: 4px;
    overflow-y: auto;
    height: calc(100% - 128px);
  }
}
.right {
  margin-left: 280px;
  padding: 16px 24px;
  width: calc(100% - 280px);
  height: calc(100vh - 150px);
  overflow: auto;
}
</style>
<style lang="less">
.data-dictionaries {
  .ant-tree.ant-tree-show-line li:not(:last-child)::before {
    position: absolute;
    left: 12px;
    width: 1px;
    height: 100%;
    margin: 13px 0 0;
    border-left: 1px solid #d9d9d9;
    content: " ";
  }
  .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    opacity: 0;
  }
  .ant-tree-treenode-switcher-close
    .ant-tree-node-content-wrapper-normal::before {
    content: "-";
    left: 12px;
    position: absolute;
    color: #d9d9d9;
  }
}
</style>
